import mustBeAuthenticated from "@/middleware/mustBeAuthenticated";
import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";

const routes = [
  {
    path: "/",
    component: () => import("@/Layouts/main/main.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/home/home.vue"),
        meta: {
          title: "Home",
        },
      },
      {
        path: "/home",
        component: () => import("@/views/home/home.vue"),
        meta: {
          title: "Home",
        },
      },
      {
        path: "/faq",
        component: () => import("@/views/faq/faq.vue"),
        meta: {
          title: "FAQ",
        },
      },
      {
        path: "/blog",
        component: () => import("@/views/blog/blog.vue"),
        meta: {
          title: "Blog",
        },
      },
      {
        path: "/blog/:blog_id",
        component: () => import("@/views/blog/detail/detail.vue"),
        meta: {
          title: "Blog Detail",
        },
      },
      {
        path: "/profile",
        component: () => import("@/views/profile/profile.vue"),
        beforeEnter: mustBeAuthenticated,
        meta: {
          title: "Profile",
        },
      },
      {
        path: "/profile/my_transaction",
        component: () =>
          import("@/views/profile/myTransaction/myTransaction.vue"),
        beforeEnter: mustBeAuthenticated,
        meta: {
          title: "My Transaction",
        },
      },
      {
        path: "/profile/my_transaction/:id",
        component: () =>
          import("@/views/profile/myTransactionDetail/myTransactionDetail.vue"),
        beforeEnter: mustBeAuthenticated,
        meta: {
          title: "Detail My Transaction",
        },
      },
      {
        path: "/profile/my_voucher",
        component: () => import("@/views/profile/myVoucher/myVoucher.vue"),
        beforeEnter: mustBeAuthenticated,
        meta: {
          title: "My Voucher",
        },
      },
      {
        path: "/profile/change_credential",
        component: () =>
          import("@/views/profile/changeCredential/changeCredential.vue"),
        beforeEnter: mustBeAuthenticated,
        meta: {
          title: "Change Credential",
        },
      },
      {
        path: "/profile/my_voucher/:id",
        component: () =>
          import("@/views/profile/myVoucherDetail/myVoucherDetail.vue"),
        beforeEnter: mustBeAuthenticated,
        meta: {
          title: "Detail My Voucher",
        },
      },
      {
        path: "/profile/point",
        component: () => import("@/views/profile/point/point.vue"),
        beforeEnter: mustBeAuthenticated,
        meta: {
          title: "My Transaction",
        },
      },
      {
        path: "/profile/tier",
        component: () => import("@/views/profile/tier/tier.vue"),
        beforeEnter: mustBeAuthenticated,
        meta: {
          title: "My Tier",
        },
      },
      // {
      //   path: "/categories/:id",
      //   component: () => import("@/views/categories/categories.vue"),
      //   meta: {
      //     title: "Categories",
      //   },
      // },
      {
        path: "/product",
        component: () => import("@/views/product/product.vue"),
        meta: {
          title: "Product",
        },
      },
      {
        path: "/halaman-pencarian",
        component: () => import("@/views/product/product.vue"),
        meta: {
          title: "Halaman Pencarian",
        },
      },
      {
        path: "/product/:id",
        component: () => import("@/views/productDetail/productDetail.vue"),
        meta: {
          title: "Product Detal",
        },
      },
      {
        path: "/cart",
        component: () => import("@/views/cart/cart.vue"),
        meta: {
          title: "Cart",
        },
      },
      {
        path: "/checkout",
        component: () => import("@/views/checkout/checkout.vue"),
        meta: {
          title: "Checkout",
        },
      },
      {
        path: "/voucher",
        component: () => import("@/views/voucher/voucher.vue"),
        meta: {
          title: "Vouchers",
        },
      },
      {
        path: "/voucher/:id",
        component: () => import("@/views/voucherDetail/voucherDetail.vue"),
        meta: {
          title: "Voucher Detail",
        },
      },
      {
        path: "/event",
        component: () => import("@/views/event/list/list.vue"),
        meta: {
          title: "Events",
        },
      },
      {
        path: "/event/:id",
        component: () => import("@/views/event/detail/detail.vue"),
        meta: {
          title: "Event",
        },
      },
      {
        path: "/term-and-condition",
        component: () =>
          import("@/views/term_and_condition/term_and_condition.vue"),
        meta: {
          title: "Term And Condition",
        },
      },
      {
        path: "/layanan/registrasi",
        component: () => import("@/views/layanan/registrasi/registrasi.vue"),
        meta: {
          title: "Registrasi",
        },
      },
      {
        path: "/layanan/pemesanan",
        component: () => import("@/views/layanan/pemesanan/pemesanan.vue"),
        meta: {
          title: "Pemesanan",
        },
      },
      {
        path: "/layanan/pembayaran",
        component: () => import("@/views/layanan/pembayaran/pembayaran.vue"),
        meta: {
          title: "Pembayaran",
        },
      },
      {
        path: "/layanan/pengiriman",
        component: () => import("@/views/layanan/pengiriman/pengiriman.vue"),
        meta: {
          title: "Pengiriman",
        },
      },
      {
        path: "/kebijakan/pengiriman",
        component: () => import("@/views/kebijakan/pengiriman/pengiriman.vue"),
        meta: {
          title: "Kebijakan Pengiriman",
        },
      },
      {
        path: "/kebijakan/retur",
        component: () => import("@/views/kebijakan/retur/retur.vue"),
        meta: {
          title: "Kebijakan Pengembalian Barang",
        },
      },
      {
        path: "/kebijakan/keuangan",
        component: () => import("@/views/kebijakan/keuangan/keuangan.vue"),
        meta: {
          title: "Kebijakan Pengembalian Uang",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/Layouts/auth/auth.vue"),
    children: [
      {
        path: "/signin",
        component: () => import("@/views/auth/signIn/signIn.vue"),
        meta: {
          title: "Sign In",
        },
      },
      {
        path: "/signup",
        component: () => import("@/views/auth/signUp/signUp.vue"),
        meta: {
          title: "Sign Up",
        },
      },
      {
        path: "/verify",
        component: () => import("@/views/auth/verify/verify.vue"),
        meta: {
          title: "Verivy Page",
        },
      },
      {
        path: "/forgot-password",
        component: () =>
          import("@/views/auth/forgotPassword/forgot-password.vue"),
        meta: {
          title: "Forgot Password",
        },
      },
      {
        path: "/to-mobile",
        component: () => import("@/views/auth/toMobile/to-mobile.vue"),
        meta: {
          title: "Forgot Password",
        },
      },
      {
        path: "/transaction/:status",
        component: () => import("@/views/redirect/redirect.vue"),
        meta: {
          title: "Redirect Page",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  store.dispatch("CANCEL_PENDING_REQUESTS");
  next();
});

export default router;
